import { URL_LOADING, URL_LOADING_DONE } from "../actions/urlLoading";

const initState = [];

export default (state = initState, action) => {
  switch (action.type) {
    case URL_LOADING:
      return [...state, action.value];
    case URL_LOADING_DONE: {
      const finalState = [...state];
      const index = finalState.indexOf(action.value);
      if (index > -1) {
        finalState.splice(index, 1);
      }
      return finalState;
    }
    default:
      return state;
  }
};
