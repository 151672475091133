import React from "react";
import * as firebase from "firebase/app";

import Main from "./components/layout/main";
import CssBaseline from "@material-ui/core/CssBaseline";
import HTTPManager from "./helper/HTTPManager";
import { IntlProvider } from "react-intl";
import messages_en from "./locale/en.json";
import messages_de from "./locale/de.json";
import "./helper/index";
import "asmi-ui-web/app.css";
import './App.css';

const App = props => {
  let randomKey = Math.random()
    .toString(36)
    .substring(7);

  const messages = {
    de: messages_de,
    en: messages_en
  };
  const language = navigator.language.split(/[-_]/)[0]; // language without region code

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  firebase.initializeApp(firebaseConfig);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="App">
        <CssBaseline />
        <HTTPManager />
        <Main key={randomKey} />
      </div>
    </IntlProvider>
  );
};

export default App;
