import {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios/index';
import {markURLLoading, unmarkURLLoading} from '../actions/urlLoading';

class HTTPManager extends Component {
    timer = null;

    state = {
      completed: 0,
    };

    componentDidMount() {
      const root = this;
      axios.defaults.withCredentials = true
      axios.interceptors.request.use(config => {
        root.props.markURLLoading(config.url);
        return config;
      }, error => {
        if (error.config && error.config.url) {
          root.props.unmarkURLLoading(error.config.url);
        }
        return Promise.reject(error);
      });

      axios.interceptors.response.use(response => {
        root.props.unmarkURLLoading(response.config.url);
        return response;
      }, error => {
        if (error.config && error.config.url) {
          root.props.unmarkURLLoading(error.config.url);
        }
        return Promise.reject(error);
      });
    }

    render() {
      return null;
    }
}

const mapStateToProps = state => {
  return {
    apiProcessing: state.apiProcessing
  };
};

export default connect(mapStateToProps, { markURLLoading, unmarkURLLoading })(HTTPManager);
