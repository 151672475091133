const env = process.env;
// const loginURL = env.REACT_APP_LOGIN_URL || '';
// const logoutURL = env.REACT_APP_LOGOUT_URL || '';
// const appPath = env.REACT_APP_PATH || '';

const globalConstants = {
  drawerWidth: 220,
  brandName: "Asmi"
};

export default globalConstants;
