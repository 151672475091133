import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { theme } from 'asmi-ui-web';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import store, {history} from './store';
import {ConnectedRouter} from 'connected-react-router';

const target = document.querySelector('#root');
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
          <App/>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  target
);
