import Loadable from "react-loadable";
import { ComponentLoader } from "asmi-ui-web";

export const Login = Loadable({
  loader: () => import("../components/auth/index"),
  loading: ComponentLoader
});

export const Logout = Loadable({
  loader: () => import("../components/auth/logout"),
  loading: ComponentLoader
});
