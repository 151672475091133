import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Grid
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { userRoutes } from "../../config/routes";
import NotFound from "./notFound";
import { colors } from "asmi-ui-web";

const Main = ({ classes }) => {
  let routes = userRoutes;
  return (
    <Router basename={process.env.REACT_APP_BASE_URL}>
      <Grid container direction="column">
        <Grid item xs sm>
          <div className={classes.logoBg}>
            <img
              src={`${process.env.PUBLIC_URL}/images/asmi_logo.svg`}
              alt={"Asmi"}
              height="25px"
            />
          </div>
        </Grid>
        <Grid item xs sm>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
            <Route component={NotFound} />
          </Switch>
        </Grid>
      </Grid>
    </Router>
  );
};

const styles = theme => ({
  logoBg: {
    padding: "1.5rem 4rem",
    width: "9rem",
    marginLeft: "1.5rem",
    backgroundColor: colors.primary.contrastText,
    boxShadow: "0 0 49.5px 0 rgba(0, 0, 0, 0.05)",
    borderRadius: "0 0 5px 5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default withStyles(styles)(Main);
