export const SHOW_PROCCESSING = 'SHOW_PROCCESSING';
export const HIDE_PROCCESSING = 'HIDE_PROCCESSING';

export const showProcessing = api => {
  return {
    type: SHOW_PROCCESSING,
    value: api,
  };
};

export const hideProcessing = api => {
  return {
    type: HIDE_PROCCESSING,
    value: api
  };
};
