import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import apiLoading from "./apiLoading";
import urlLoading from "./urlLoading";

export default history =>
  combineReducers({
    router: connectRouter(history),
    apiLoading,
    urlLoading
  });
