import {
  Login,
  Logout
} from "./asyncRoutes";

export const userRoutes = [
  {
    path: "/",
    exact: true,
    label: "Login",
    component: Login,
  },
  {
    path: "/forgot",
    exact: true,
    label: "Login",
    component: Login,
  },
  {
    path: "/verify",
    exact: true,
    label: "Login",
    component: Login,
  },
  {
    path: "/",
    exact: true,
    label: "Login",
    component: Logout,
  },
]
