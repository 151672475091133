import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import rootReducer from "./reducers/index";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_URL
});
const enhancers = [];
const initialState = {};
const devMiddlewares = [];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line no-underscore-dangle

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }

  // devMiddlewares.push(logger);
}

const store = createStore(
  rootReducer(history), // new root reducer with router state
  initialState,
  compose(
    applyMiddleware(
      routerMiddleware(history), // for dispatching history actions
      thunk,
      ...devMiddlewares
    ),
    ...enhancers
  )
);

export default store;
