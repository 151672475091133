import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { CustomFormattedMessage } from "asmi-ui-web";

const NotFound = props => {
  return (
    <Fragment>
      <Typography>
        <CustomFormattedMessage
          textTransform="capitalize"
          id="generic.error.pageNotFoundTitle"
        />
      </Typography>
      <Typography>
        <CustomFormattedMessage
          textTransform="capitalize"
          id="generic.error.pageNotFoundMessage"
        />
      </Typography>
    </Fragment>
  );
};

export default NotFound;
