export const URL_LOADING = "URL_LOADING";
export const URL_LOADING_DONE = "URL_LOADING_DONE";

export const markURLLoading = value => {
  return {
    type: URL_LOADING,
    value
  };
};

export const unmarkURLLoading = value => {
  return {
    type: URL_LOADING_DONE,
    value
  };
};
