import {HIDE_PROCCESSING, SHOW_PROCCESSING} from '../actions/apiLoading';

const initialState = {};

export default (state = initialState, action = {type: ''}) => {
  switch (action.type) {
    case SHOW_PROCCESSING: {
      return action.value ? {...state, [action.value]: true} : { ...state };
    }
    case HIDE_PROCCESSING: {
      return action.value ? {...state, [action.value]: false} : { ...state };
    }
    default:
      return state;
  }
};
